<template>
  <div>
    <loader
      v-if="Loadmore"
      object="#9658cc"
      color1="#532a76"
      color2="#d3bae9"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="30"
      name="circular"
    />
    <!-- <this-header /> -->
    <div class="container-sm-lot pl-1 pr-1 p-relative">
      <div class="top-bxt my-1">
        <img
          src="/icon/back.svg"
          alt="back"
          class="cursor-pointer ts-left"
          @click="$router.go(-1)"
        >

        <h3 class="text-primary mb-0">
          รายการสลากฯ
        </h3>
      </div>

      <div>
        <p class="text mt-1 mb-0 text-primary">
          งวดวันที่ {{ roundth }}
        </p>
        <div
          v-for="(item, index) in HisDetail.MyCart"
          :key="index"
          class="card-list mt-1 border-0 mb-2"
          :class="item.status == 3 ? 'p-green' : 'p-light'"
        >
          <h3
            v-if="item.status == 3"
            class="bg-lot mr-1 text-white"
          >
            {{ item.lottonumber }}
          </h3>

          <h3
            v-else
            class="text-primary bg-lot mr-1"
          >
            {{ item.lottonumber }}
          </h3>

          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <div>
                <p class="small mb-0 text-primary">
                  วันที่ {{ item.roundth }}
                </p>

                <p
                  class="small mb-0 mt-n50"
                  :class="item.status == 3 ? 'text-white' : 'text-muted'"
                >
                  หมายเลขออเดอร์ {{ item.order_id }}
                </p>
              </div>
            </div>
          </div>

          <div
            v-if="item.status === 3 && item.receive_reward === 0"
            class="posi-rig-cliam"
            @click="ReceiveReward(item._id)"
          >
            <small class="text-primary"> รับเงิน </small>
          </div>

          <div
            v-if="item.status === 3"
            class="posi-rig"
          >
            <small class="text-primary">
              เงินรางวัล
              {{
                item.reward_pay
                  ? Commas(Number(item.reward_pay).toFixed(2))
                  : 0.0
              }}
              <small class="text-white">₩</small>
            </small>
          </div>

          <div
            v-if="item.status == 2"
            class="reward-popup"
            style="background-color: purple; color: white"
          >
            รอผลรางวัล
          </div>

          <div
            v-if="item.status == 3"
            class="reward-popup cursor-pointer"
            @click="showReward(item)"
          >
            {{ item.reward_status }}
          </div>

          <div
            v-if="item.status == 4"
            class="reward-popup"
            style="background-color: white; color: #532a76"
          >
            ไม่ถูกรางวัล
          </div>
        </div>

        <div class="demo-spacing-0 d-flex justify-content-center">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            @input="GetHisDetail()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
    </div>

    <b-modal
      ref="receice-reward"
      hide-footer
      hide-header
      centered
    >
      <div class="text-center">
        <h3 class="mb-0 text-primary mt-2">
          ขึ้นเงินรางวัลเรียบร้อยแล้วค่ะ
        </h3>

        <img
          src="/icon/coin-bag.png"
          alt="bag"
        >
        <div>
          <button
            class="btn-gray-txt p-75 w-50 my-2"
            @click="$refs['receice-reward'].hide()"
          >
            ยืนยัน
          </button>
        </div>
      </div>
    </b-modal>

    <ThisFooter />
  </div>
</template>

<script>
import { BPagination } from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies, no-unused-vars
import moment from 'moment-timezone'
import ThisFooter from '../components/ThisFooter.vue'
// import ThisHeader from '../components/ThisHeader.vue'

export default {
  name: 'Home',
  components: {
    // ThisHeader,
    BPagination,
    ThisFooter,
  },
  data() {
    return {
      Loadmore: false,
      currentPage: 1,
      perPage: 5,
      rows: 1,
      isActive: 'last',
      userData: JSON.parse(localStorage.getItem('userData')), // getlocalstorage
      balance: 0,
      balance_withdraw: 0,
      balance_datecheck: '',
      HisDetail: {
        MyCart: [],
        total: 0,
      },
      reward: [],
      roundth: JSON.parse(localStorage.getItem('roundth')),
    }
  },
  created() {
    if (!this.userData) {
      this.$router.push({ name: 'home' })
    }
  },
  mounted() {
    this.GetHisDetail()
  },
  methods: {
    ReceiveReward(id) {
      const Obj = {
        id,
      }
      this.$http
        .post('/lotto-suvarn/ReceiveReward', Obj)
        // eslint-disable-next-line no-unused-vars
        .then(ress => {
          this.$refs['receice-reward'].show()
          // this.Success(ress.data.message)
          this.GetHisDetail()
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
        })
    },
    GetHisDetail() {
      this.Loadmore = true
      const data = {
        page: this.currentPage,
        perpage: this.perPage,
        group_id: this.$route.params.id,
      }
      this.$http
        .post('lotto-suvarn/MyLottoHistory/Detail', data)
        .then(ress => {
          this.Loadmore = false
          // console.log(ress.data)
          this.HisDetail = ress.data
          this.rows = ress.data.total
        })
    },
    Commas(x) {
      if (!x) {
        return 0
      }
      if (x % 1 !== 0) {
        return Number(x)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>

  <style scoped></style>
